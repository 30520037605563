<template>
  <div>
    <el-form
      :model="register"
      :rules="rules"
      ref="payInfo"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="手机号或邮箱验证码"
        prop="phoneOrEmailCode"
        placeholder="请输入邮箱或者手机号验证码"
      >
        <el-input v-model="register.code" style="width: 200px" />
      </el-form-item>
      <el-form-item
        label="用户头像"
        prop="uploadImg"
        placeholder="请点击上传用户头像"
      >
        <el-upload
          class="avatar-uploader"
          action=""
          :http-request="(params) => uploadImage(params)"
          :on-remove="(file) => removeImage(file)"
          list-type="picture"
          accept="image/jpeg"
          :limit="1"
          :file-list="fileList"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="" />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
        <el-alert
          title="文件上传成功"
          type="success"
          v-if="successAlertDisabled"
          show-icon
        />
        <el-alert
          title="文件上传失败"
          type="error"
          v-if="errorAlertDisabled"
          show-icon
        />
      </el-form-item>
      <el-form-item label="用户名" prop="username" placeholder="请输入用户名">
        <el-input v-model="register.username" style="width: 200px" />
      </el-form-item>
      <el-form-item label="手机号" prop="phone" placeholder="请输入手机号">
        <el-input
          :disabled="phoneDisabled"
          v-model="register.phone"
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item label="邮箱" prop="mail" placeholder="请输入邮箱">
        <el-input
          :disabled="mailDisabled"
          v-model="register.mail"
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item label="密码" prop="password" placeholder="请输入密码">
        <el-input show-password v-model="register.pwd" style="width: 200px" />
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="createForm(register)"
          v-loading.fullscreen.lock="fullscreenLoading"
          >注册
        </el-button>
        <el-button @click="createForm(register)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Plus } from "@element-plus/icons-vue";

export default {
  components: {
    Plus,
  },
  computed: {
    ...mapState({
      sendCodeType: (state) => state.Account.sendCodeType,
      accountTo: (state) => state.Account.accountTo,
      registerStatus: (state) => state.Account.registerStatus,
      headImg: (state) => state.Account.headImg,
      token: (state) => state.Account.token,
      sendCodeStatus: (state) => state.Account.sendCodeStatus,
    }),
  },
  data() {
    return {
      register: {
        code: "",
        headImg: "",
        username: "",
        phone: "",
        mail: "",
        pwd: "",
      },
      rules: {
        phoneOrEmailCode: [
          {
            required: true,
            message: "请输入手机号或邮箱验证码",
            trigger: "clear",
          },
        ],
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "clear",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "clear",
          },
        ],
        mail: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "clear",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "clear",
          },
        ],
      },
      fullscreenLoading: false,
      phoneDisabled: true,
      mailDisabled: true,
      imageUrl: "",
      successAlertDisabled: false,
      errorAlertDisabled: false,
      fileList: [],
    };
  },
  methods: {
    createForm(register) {
      this.$store.dispatch("register", register);
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
        console.log(this.registerStatus);
        if (this.registerStatus) {
          this.$router.push({ name: "login" });
        }
      }, 4000);
    },
    loadParams() {
      if (this.sendCodeType === "MAIL") {
        this.phoneDisabled = true;
        this.mailDisabled = false;
        this.register.mail = this.accountTo;
      } else if (this.sendCodeType === "PHONE") {
        this.phoneDisabled = false;
        this.mailDisabled = true;
        this.register.phone = this.accountTo;
      }
    },
    uploadImage(params) {
      let uploadData = new FormData();
      uploadData.append("file", params.file);
      this.$store.dispatch("uploadImg", uploadData);
      setTimeout(() => {
        this.successAlertDisabled = false;
        this.errorAlertDisabled = false;
        console.log(this.headImg);
        if (this.headImg !== "") {
          this.register.headImg = this.headImg;
          this.successAlertDisabled = true;
        } else {
          this.errorAlertDisabled = true;
        }
      }, 6000);
    },
    removeImage(file) {
      console.log(file);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
  created() {
    this.loadParams();
    if (this.sendCodeStatus === false) {
      this.$message.error("非法访问！");
    }
  },
};
</script>

<style scoped>
.avatar-uploader .avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  text-align: center;
}
</style>
